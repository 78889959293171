// Copyright The OpenTelemetry Authors
// SPDX-License-Identifier: Apache-2.0

import { CypressFields } from '../../utils/Cypress';
import { Product } from '../../protos/demo';
import ProductPrice from '../ProductPrice';
import * as S from './ProductCard.styled';
import { useState, useEffect } from 'react';
import { useFlags } from '../../providers/FeatureFlag.provider';
import { getImageWithDelay } from '../../utils/DelayedImage';

interface IProps {
  product: Product;
}

const ProductCard = ({
  product: {
    id,
    picture,
    name,
    priceUsd = {
      currencyCode: 'USD',
      units: 0,
      nanos: 0,
    },
  },
}: IProps) => {

  const { loaded, flags } = useFlags();
  const slowLoadEnabled = flags.get('imageSlowLoad')?.enabled;
  const [imageSrc, setImageSrc] = useState<string>('');

  useEffect(() => {
    if(loaded && picture){
      getImageWithDelay(picture, slowLoadEnabled).then(blob => {
        setImageSrc(URL.createObjectURL(blob));
      });
    }
  }, [picture, loaded, slowLoadEnabled]);

  return (
    <S.Link href={`/product/${id}`}>
      <S.ProductCard data-cy={CypressFields.ProductCard}>
        <S.Image $src={imageSrc} />
        <div>
          <S.ProductName>{name}</S.ProductName>
          <S.ProductPrice>
            <ProductPrice price={priceUsd} />
          </S.ProductPrice>
        </div>
      </S.ProductCard>
    </S.Link>
  );
};

export default ProductCard;

// Copyright The OpenTelemetry Authors
// SPDX-License-Identifier: Apache-2.0

export async function getImageWithDelay(url: RequestInfo, enabled: boolean|undefined) {

  const min = 1500;
  const max = 3000;
  const delay = enabled ? Math.floor(Math.random() * (max - min + 1) + min) : 0;

  const headers = { 'x-envoy-fault-delay-request': delay.toString(), 'Cache-Control': 'no-cache' };
  const res = await fetch(url, { headers });
  return await res.blob();
}
